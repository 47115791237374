import { graphql } from 'overmind-graphql';

import * as userMutations from './user/mutations'
import * as userQueries from './user/queries'
import * as userSubscriptions from './user/subscriptions'

import * as loginTokenMutations from './loginToken/mutations'
import * as loginTokenQueries from './loginToken/queries'

import * as notificationMutations from './notification/mutations'
import * as notificationQueries from './notification/queries'

import * as googleMutations from './google/mutations'
import * as googleQueries from './google/queries'

import * as siteMutations from './site/mutations'
import * as siteQueries from './site/queries'

import * as eventMutations from './event/mutations';
import * as eventQueries from './event/queries';

import * as cartMutations from './cart/mutations';
import * as cartQueries from './cart/queries';

import * as productMutations from './product/mutations';
import * as productQueries from './product/queries';

import * as searchQueries from './search/queries';

import * as orderMutations from './order/mutations';
import * as orderQueries from './order/queries';

import * as paymentMutations from './paymentMethod/mutations';
import * as paymentQueries from './paymentMethod/queries';

import * as walletMutations from './wallet/mutations';
import * as walletQueries from './wallet/queries';

export default graphql({
  subscriptions: {
    ...userSubscriptions,
  },
  queries: {
    ...userQueries,
    ...loginTokenQueries,
    ...notificationQueries,
    ...googleQueries,
    ...siteQueries,
    ...eventQueries,
    ...cartQueries,
    ...productQueries,
    ...searchQueries,
    ...orderQueries,
    ...paymentQueries,
    ...walletQueries
  },
  mutations: {
    ...userMutations,
    ...loginTokenMutations,
    ...notificationMutations,
    ...googleMutations,
    ...siteMutations,
    ...eventMutations,
    ...cartMutations,
    ...productMutations,
    ...orderMutations,
    ...paymentMutations,
    ...walletMutations
  }
})
