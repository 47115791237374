<template>
  <div class="clearfix">
    <Header class=""/>
    <div class="content">
      <slot />
      <LoginModal />
    </div>
    <Footer class="" />
    <!--  -->
    <RegisterModal />
    <Support />
    <Payments :update-select-payment="updatePayment"/>
    <Share />
    <AddPayment />
    <Alert :type="state.alert.type" :message="state.alert.message" :visible="state.alert.visible"/>
  </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";

import LoginModal from '@/pages/modals/Login'
import RegisterModal from '@/pages/modals/Register'
import Payments from "../pages/modals/Payments";
import Support from "../pages/modals/Support";
import Share from "../pages/modals/Share";
import AddPayment from "../pages/modals/AddPayment";
import Alert from "../components/Alert";
import {Settings} from '../../settings';
import $ from 'jquery';

export default {
  name: "Default",
  components: {
    Payments,
    Header,
    Footer,
    LoginModal,
    RegisterModal,
    Support,
    Share,
    AddPayment,
    Alert
  },
  setup() {
    const updatePayment = () => {};
    return {updatePayment}
  },
  mounted() {
    if (this.$route?.path === '/contact') {
      if (Settings.flow === 'modal') {
        $('#supportModal').modal('show');
      } else {
        this.$router.push('/support')
      }
    }
  },
  watch: {
    $route: function () {
      window.scrollTo(0,0);
    },
  }
}
</script>
