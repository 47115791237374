<template lang="html">
  <div class="modal fade" id="registerModal" tabindex="-1" role="dialog" aria-hidden="true" ref="registerRef"
       style="padding-right: 0 !important;">
    <div class="modal-dialog modal-dialog-centered modal-mobile" role="document" style="height: 653px; margin:auto">
      <div class="modal-content" style="border-radius: 20px !important; height: 653px;">
        <div class="popup-login">
          <button class="btn close-btn" data-dismiss="modal" style="top: 12px;">X</button>
          <div class="login-title" style="margin-top: 32px;">REGISTRATION</div>
          <div class="login-desc">Login to take advantage of this special offer.</div>
          <input id="upload-input" style="outline: none; display: none" type="file" @change="onFileChange"
                 accept="image/*"/>
          <div class="profile">
            <img src="@/assets/images/icon_women.png" style="height: 170px; margin-top: 10px;" v-if="!avatar">
            <img :src="avatar" style="width: 100%; height: 100%; border-radius: 10px; object-fit: cover" v-else>
            <button class="btn upload">
              <img src="@/assets/images/icon_camera.png" style="width: 22px;">
              <label for="upload-input" class="upload-text">UPLOAD YOUR PIC</label>
            </button>
          </div>
          <div>
            <div class="field" style="width: 100%">
              <div class="field-name" style="margin-right: 10px; width: 10%">
                TITLE
                <dropdown-menu v-model="show" :right="false" class="field-area"
                               style="padding-bottom: 9px; height: 28px; width: 40px;">
                  <a class="dropdown-toggle">
                    <a style="justify-content: space-between; align-items: center; display: flex; flex-direction: row;">
                      <div>{{gender}}</div>
                      <img src="@/assets/images/icon_arrow_down.png" style="width:30%">
                    </a>
                  </a>
                  <div slot="dropdown">
                    <a class="dropdown-item" @click="selectGender('Mr')">Mr</a>
                    <a class="dropdown-item" @click="selectGender('Ms')">Ms</a>
                    <a class="dropdown-item" @click="selectGender('Mrs')">Mrs</a>
                  </div>
                </dropdown-menu>
              </div>
              <div class="field-name" style="margin-right: 10px; width: 36%">
                FIRST NAME
                <div>
                  <input class="field-area" style="width: 100%" v-model="firstName">
                </div>
              </div>
              <div class="field-name" style="width: 36%">
                LAST NAME
                <div>
                  <input class="field-area" style="width: 100%; " v-model="lastName">
                </div>
              </div>
            </div>

          </div>
          <div class="field-name" style="flex-direction: column; margin: 35px 25px 0 25px">
            <div>EMAIL ADDRESS</div>
            <input class="field-area" style="width: 100%;" v-model="email">
          </div>
          <button class="btn btn-login d-flex justify-content-center align-items-center"
                  style="position: absolute; bottom: 0; width: 92%;" type="button" @click="onClickContinue">
            <div class="loader" v-if="state.isLoading"></div>
            <div v-else>CONTINUE</div>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {reactive, toRefs} from '@vue/composition-api';
  import {isEmpty, get} from "lodash";
  import $ from 'jquery';
  import {json} from 'overmind';
  import DropdownMenu from '@innologica/vue-dropdown-menu';

  export default {
    components: {
      DropdownMenu
    },
    setup(props, {root}) {
      console.log(root.state.currentUser, '================')
      const data = reactive({
        id: root.state.currentUser?.id,
        avatar: json(root.state.currentUser?.avatar),
        firstName: json(root.state.currentUser?.firstName),
        lastName: json(root.state.currentUser?.lastName),
        email: json(root.state.currentUser?.email),
        gender: json(root.state.currentUser?.gender),
        show: false,
        get,
      });
      const _checkValid = () => {
        if (isEmpty(data.gender)) {
          return 'Please select title';
        }
        if (isEmpty(data.firstName)) {
          return 'Please enter first name';
        }
        if (isEmpty(data.lastName)) {
          return 'Please enter last name'
        }
        if (isEmpty(data.email)) {
          return 'Please enter email address';
        }
        if (!data.email.includes('@') || !data.email.includes('.')) {
          return 'Please enter valid email address';
        }

        return 'valid';
      };
      const onClickContinue = async () => {
        const valid = _checkValid();
        if (valid !== 'valid') {
          root.actions.alert.showError({message: valid});
          return;
        }
        try {
          let needUpdate = false;
          const params = {
            settings: {}
          };

          if (data.firstName !== root.state.currentUser?.firstName) {
            params.firstName = data.firstName;
            needUpdate = true;
          }
          if (data.lastName !== root.state.currentUser?.lastName) {
            params.lastName = data.lastName;
            needUpdate = true;
          }
          if (data.gender !== root.state.currentUser?.gender) {
            if (data.gender === "Mr") {
              params.gender = "Male";
            } else {
              params.gender = "Female";
            }
            needUpdate = true;
          }
          if (data.email !== root.state.currentUser?.email) {
            params.email = data.email;
            needUpdate = true;
          }

          if (data.avatar !== data.currentUser?.avatar) {
            params.avatar = data.avatar;
            needUpdate = true;
          }

          // if (isEmpty(data.avatar) ) {
          //   if (await _askSelectAvatar()) {
          //     return;
          //   }
          // }
          await root.actions.startLoading();
          if (needUpdate) {
            const user = await root.actions.user.saveUser(params);
            if (user) {
              root.actions.alert.showSuccess({message: 'Updated user profile successfully.'});
              $('#registerModal').modal('hide');
            } else {
              root.actions.alert.showError({message: 'Failed to update user profile', title: 'Flute'});
            }
          }
        } catch (ex) {
          console.log(ex);
          root.actions.alert.showError({message: 'Failed to update user profile', title: 'Flute'});
        } finally {
          await root.actions.finishLoading();
        }
      };
      const createImage = async (file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          data.avatar = e.target.result;
        };
        reader.readAsDataURL(file);
      };
      const onFileChange = (ev) => {
        const files = ev.target.files;
        createImage(files[0]);
      };

      const getUserInfo = async () => {
        const user = root.state.currentUser;
        if (user?.id) {
          data.id = user?.id;
          data.avatar = user?.avatar;
          data.firstName = user?.firstName;
          data.lastName = user?.lastName;
          data.email = user?.email;
          data.gender = user?.gender === 'Male' ? 'Mr' : user.gender === 'Female' ? 'Ms' : '';
        }
      };
      const selectGender = (gender) => {
        data.gender = gender;
      };
      return {
        onClickContinue,
        onFileChange,
        getUserInfo,
        selectGender,
        ...toRefs(data)
      }
    },
    beforeMount() {
      this.getUserInfo();
    },
    beforeUpdate() {
      if (this.state.currentUser?.id !== this.id) this.getUserInfo();
    },
    watch: {
      $route: function () {
        this.getUserInfo();
      }
    },

  }
</script>

<style lang="css" scoped>
  select {
    border: 0;
    outline: 0;
  }

  .profile {
    margin: 15px 25px 32px 25px;
    background: #d2d2d2;
    border-radius: 8px;
    height: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .upload {
    position: absolute;
    top: 237px;
    left: 34px;
    width: 170px;
    height: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: black;
    border-radius: 3px;
    padding: 0 8px;
  }

  .upload-text {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: white;
    margin-top: 7px;
  }

  .field {
    display: flex;
    margin-left: 25px;
    margin-right: 25px;
    flex: 1;
  }

  .field-name {
    font-weight: 300;
    font-size: 10px;
    line-height: 12px;
  }

  .field-area {
    display: flex;
    border-bottom-width: 1px !important;
    border-left-width: 0 !important;
    border-right-width: 0 !important;
    border-top-width: 0 !important;
    border-style: solid !important;
    border-color: black !important;
    justify-content: space-between;
    align-items: center;
    font-weight: 300;
    font-size: 13px;
    line-height: 18px;
    padding-bottom: 8px;
    padding-left: 2px;
    padding-right: 2px;
    /*margin-right: 10px;*/
  }

  input.field-area:focus {
    outline-width: 0 !important;
  }
</style>
