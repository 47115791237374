<template lang="html">
	<div class="modal fade" id="supportModal" tabindex="-1" role="dialog" aria-labelledby="supportModalLabel" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered modal-mobile" role="document" style="height: 653px; margin:auto">
			<div class="modal-content" style="border-radius: 20px !important; height: 653px; margin: 0 10px;">
				<button class="btn close-btn" data-dismiss="modal" style="top: 27px;">X</button>
				<div class="popup-login">
					<div class="login-title" style="margin-top: 32px; margin-bottom: 10px;">Contact Support</div>
					<div class="field-name" style="flex-direction: column; margin: 35px 25px 0 25px">
						<div>NAME</div>
						<input class="field-area" style="width: 100%;" v-model="name">
					</div>
					<div class="field-name" style="flex-direction: column; margin: 35px 25px 0 25px">
						<div>SUBJECT</div>
						<input class="field-area" style="width: 100%;" v-model="subject">
					</div>
					<div class="field-name" style="flex-direction: column; margin: 35px 25px 90px 25px">
						<div>MESSAGE</div>
						<textarea class="field-area" style="width: 100%;" v-model="message" rows="4"></textarea>
					</div>
					<button class="btn btn-login d-flex justify-content-center align-items-center" style="position: absolute; bottom: 0; width: 92%;" type="button" @click="onPressSend">
						<div class="loader" v-if="state.isLoading"></div>
						<div v-else>SEND</div>
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
  import {reactive, toRefs} from '@vue/composition-api';
  import $ from 'jquery';

  export default {
    setup(props, {root}) {
      const data = reactive({
        name: 'Famous Jerk Flex',
        subject: null,
        message: null
      });

      const onPressSend = async () => {
        if (!root.state.currentUser?.id) {
          root.actions.alert.showError({message: 'You mush login first!'});
          return;
        }
        await root.actions.startLoading();
        try {
          const params = {userId: root.state.currentUser?.id, subject: data.subject, message: data.message}
          await root.actions.user.requestSupport(params);
          $('#supportModal').modal('hide');
          root.actions.alert.showSuccess({message: 'Your message was sent successfully!'});
        } catch (e) {
          console.log(e)
        } finally {
          await root.actions.finishLoading();
        }
      };
      
      return {
        onPressSend,
        ...toRefs(data)
      }
    },
  }
</script>

<style lang="css" scoped>
	textarea:hover {
		outline-width: 0 !important;
	}
	
	textarea {
		outline-width: 0 !important;
	}
	
	.field-name {
		font-weight: 300;
		font-size: 10px;
		line-height: 12px;
	}
	
	.field-area {
		display: flex;
		border-bottom-width: 1px !important;
		border-left-width: 0 !important;
		border-right-width: 0 !important;
		border-top-width: 0 !important;
		border-style: solid !important;
		border-color: black !important;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		font-weight: 300;
		font-size: 15px;
		line-height: 18px;
		padding-bottom: 8px;
		padding-left: 2px;
		padding-right: 2px;
	}
	
	input.field-area:focus {
		outline-width: 0 !important;
	}
</style>
