<template>
  <div class="home-footer row" v-if="currentRouteName !== 'Home'">
    <div class="col-xl-6 col-lg-12">
      <div class="row d-flex" style="padding: 0 !important;">
        <div class="col-xl-2 col-lg-12" style="padding: 0 !important;">
          <img src="@/assets/images/icon_bottom_red_logo.png" class="img-fluid"/>
        </div>
        <div class="col-xl-9 col-lg-12 d-flex flex-column justify-content-center home-bottom-padding"
             style="padding: 0 !important;">
          <div class="d-flex flex-row align-items-center">
            <a href="https://instagram.com/famousjerks" target="_blank"><img src="@/assets/images/icon_instagram.png"
                                                                             class="mr-2"></a>
            <a href="https://www.facebook.com/famousjerk" target="_blank"><img src="@/assets/images/icon_facebook.png"
                                                                               class="ml-2 mr-2"></a>
            <a href="https://twitter.com/famousjerks" target="_blank"><img src="@/assets/images/icon_twitter.png"
                                                                           class="ml-2 mr-2"></a>
          </div>
          <div style="display: flex; flex-direction: row; margin-top: 7px;">
            <router-link :to="{path: '/privacy', query: isEvent ? {event: 'hush'}: {}}" class="text1">Privacy Policy
            </router-link>
            <span class="text1 ml-1 mr-1"> • </span>
            <router-link :to="{path: '/terms', query: isEvent ? {event: 'hush'}: {}}" class="text1">Terms of Service
            </router-link>
            <span class="text1 ml-1 mr-1"> • </span>
            <a class="text1" @click="onClickSupport">Support</a>
          </div>
          <div class="text1">2021 Famous Jerk. All Rights Reserved.</div>
        </div>
      </div>
    </div>
    <div class="col-xl-6 col-lg-12 d-flex justify-content-end home-bottom-padding" style="padding: 0 !important;">
      <a
        href="https://apps.apple.com/us/app/famous-jerk/id1543167871"
        target="_blank"
        style="text-align: right"
        class="mr-2"
      >
        <img src="@/assets/images/btn_apple.png" alt="" class="img-fluid" style="width: 80%"
        /></a>
      <a
        href="https://play.google.com/store/apps/details?id=com.famous.jerk&hl=es_US&gl=US"
        target="_blank"
      >
        <img src="@/assets/images/btn_google.png" alt="" class="img-fluid" style="width: 80%"
        /></a>
    </div>
  </div>

</template>

<script>
  import {Settings} from '../../settings';
  import $ from 'jquery'

  export default {
    computed: {
      currentRouteName() {
        return this.$route.name;
      },
      isModal() {
        return Settings.flow === 'modal'
      },
      isEvent() {
        return this.$route.query.event === 'hush';
      }
    },
    methods: {
      onClickSupport() {
        if (Settings.flow === 'modal') {
          $('#supportModal').modal('show');
        } else {
          this.$router.push('/support');
        }
      }
    }
  }
</script>

<style lang="css" scoped>

  .text1 {
    font-size: 13px;
    line-height: 16px;
  }

  .home-footer {
    border: 1px solid #dadada;
    border-left-width: 0px;
    border-right-width: 0;
    border-bottom-width: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 300px 30px 0 30px;
    padding: 32px 0 30px 0;
  }

  /* */
  @media (max-width: 1024px) {
    .home-footer {
      margin-top: 150px;
    }
  }

  @media (max-width: 767px) {
    .home-footer {
      margin-top: 75px;
    }

    .home-bottom-padding {
      margin-top: 20px;
    }
  }
</style>
