import {Settings} from '../../settings';
import * as internalActions from './internalActions';
import store from 'store/dist/store.modern';
import {_} from 'vue-underscore';
import {json} from "overmind";

// const TOKEN_NAME = Settings.appName.toLowerCase() + '-token';
const TOKEN_NAME = '@' + Settings.appName.toLowerCase().replace(/s/g, '') + ':token';

export const internal = internalActions;

/*
*
*/
export const isProfileUnset = async ({state}) => {
  return _.isEmpty(state.currentUser.email) ||
    _.isEmpty(state.currentUser.fullName) ||
    _.isEmpty(state.currentUser.id);
};

/*
*
*/
export const createAppUser = async ({state, effects, actions}, variables) => {
  console.log('creating app user...', variables);

  try {
    const user = await effects.gql.mutations.createAppUser(variables);
    console.log(user.createAppUser.user, 'createAppUser');
    state.currentUser = user.createAppUser.user;
    await actions.user.onCreateBraintreeToken();
    return user.createAppUser;
  } catch (e) {
    console.log(e)
  }
};

/*
*
*/
export const verifySmsCode = async ({effects}, variables) => {
  console.log('verifying sms code...', variables);
  const result = await effects.gql.mutations.verifySmsCode(variables);
  return result.verifySmsCode;
};

/*
*
*/
export const setStoredAuthToken = async ({state}) => {
  return store.set(TOKEN_NAME, {token: state.authToken, userId: state.currentUser.id});
};

/*
*
*/
export const getStoredAuthToken = async () => {
  if (!store.get(TOKEN_NAME)) return store.set(TOKEN_NAME, {token: null, userId: null});
  return store.get(TOKEN_NAME);
};

/*
*
*/
export const removeStoredAuthToken = async () => {
  return store.remove(TOKEN_NAME);
};

/*
*
*/
export const logout = async ({state, actions}) => {
  console.log('Logout');
  state.currentUser = null;
  state.isLoggedIn = false;
  state.authToken = null;
  state.currentCart = null;
  await localStorage.removeItem('famous_jerk_currentUser');
  await localStorage.removeItem('famous_jerk_currentCart');
  await localStorage.removeItem('famous_jerk_order');
  await localStorage.removeItem('famous_jerk_user');
  await actions.removeStoredAuthToken();
  return true;
};

/*
*
*/
export const login = async ({effects, state, actions}, variables) => {
  try {
    const {login} = await effects.gql.mutations.login(variables);

    state.currentUser = login.user;
    state.isLoggedIn = true;
    state.authToken = login.token;

    await actions.setStoredAuthToken();
    return true;

  } catch (e) {
    await actions.removeStoredAuthToken();
    state.errors = e.response.errors;
  }
}

/*
*
*/
export const loginWithToken = async ({effects, state, actions}, variables) => {
  console.log('logging in with token...', variables);

  if (!variables.token || !variables.userId) {
    state.isAuthenticating = false;
    await actions.logout();
    return;
  }

  try {
    const {loginWithToken} = await effects.gql.mutations.loginWithToken(variables);

    state.currentUser = loginWithToken.user;
    state.currentUser.paymentMethods = (loginWithToken.user?.paymentMethods || []).filter(p => p.type === 'payment' && p.isValid);
    state.isLoggedIn = true;
    state.authToken = variables.token;
    state.isAuthenticating = false;
    await actions.setStoredAuthToken();
    await actions.user.onCreateBraintreeToken();
    await actions.order.getOrders({where: {user: {id: state.currentUser.id}}});
    // await actions.user.findSocialUsers();
    // await actions.user.userAcceptedCampaigns();
    await actions.wallet.getWallets({userId: state.currentUser.id});
    // await actions.user.getUserTabs();
    await actions.cart.getCarts({where: {user: {id: state.currentUser.id}, isPending: true}});
    return true;

  } catch (e) {
    console.log('e', e.message, Object.keys(e));
    if (e.message?.includes('Could not verify token')) {
      state.isAuthenticating = false;
      await actions.removeStoredAuthToken();
      await actions.logout();
      if (state.route) {

        json(state.route).push('/');
      }
    }

    return false;
  }
};

export const loginWithTokenForBraintree = async ({effects, state, actions}, variables) => {

  if (!variables.token || !variables.userId) {
    return;
  }

  try {
    const {loginWithToken} = await effects.gql.mutations.loginWithToken(variables);

    state.currentUser = loginWithToken.user;
    state.isLoggedIn = true;
    state.authToken = variables.token;
    state.isAuthenticating = false;
    await actions.user.onCreateBraintreeToken();
    await actions.setStoredAuthToken();
    return true;

  } catch (e) {
    return false;
  }
};

export const setRoute = ({state}, route) => {
  state.route = route;
};

/*
*
*/
export const focusInput = ({state}) => {
  state.errors = [];
};

/*
*
*/
export const connectionChanged = ({state}, connected) => {
  state.connected = connected;
};


export const setErrors = ({state}, errors) => {
  state.errors = errors
};


/*
*
*/
export const signInClicked = ({state}, redirectTo) => {
  state.signInModalOpen = true;
  state.redirectOnLogin = redirectTo || '';
};

/*
*
*/
export const signOutClicked = async ({state, effects, actions}) => {
  effects.analytics.track('Sign Out', {});
  state.workspace.openedWorkspaceItem = 'files';
  if (state.live.isLive) {
    actions.live.internal.disconnect();
  }
  await effects.api.signout();
  effects.jwt.reset();
  state.currentUser = null;
  effects.browser.reload();
};

/*
*
*/
export const signInButtonClicked = async ({actions, state}, options) => {
  if (!options) {
    await actions.internal.signIn({
      useExtraScopes: false,
    });
    state.signInModalOpen = false;
    return;
  }
  await actions.internal.signIn(options);
  state.signInModalOpen = false;
};

/*
*
*/
export const modalOpened = ({state, effects}, props) => {
  effects.analytics.track('Open Modal', {modal: props.modal});
  state.currentModal = props.modal;
  if (props.modal === 'preferences' && props.itemId) {
    state.preferences.itemId = props.itemId;
  } else {
    state.currentModalMessage = props.message || null;
  }
};

/*
*
*/
export const modalClosed = ({state}) => {
  state.currentModal = null;
};

/*
*
*/
export const toggleSignInModal = ({state}) => {
  state.signInModalOpen = !state.signInModalOpen;
};

/*
*
*/
export const addNotification = ({effects}, {message, type, timeAlive}) => {
  console.log(type, 'type addNotification')
  effects.notificationToast.add({
    message,
    // status: effects.notificationToast.convertTypeToStatus(type),
    timeAlive: timeAlive * 1000,
  });
};

/*
*
*/
export const removeNotification = ({state}, id) => {
  const notificationToRemoveIndex = state.notifications.findIndex(
    notification => notification.id === id
  );

  state.notifications.splice(notificationToRemoveIndex, 1);
};

/*
*
*/
export const notificationAdded = ({effects}, {title, notificationType, timeAlive}) => {
  console.log(notificationType, 'notificationType notificationAdded')
  effects.notificationToast.add({
    message: title,
    // status: convertTypeToStatus(notificationType),
    timeAlive: timeAlive ? timeAlive * 1000 : undefined,
  });
};

/*
*
*/
export const notificationRemoved = ({state}, {id}) => {
  const {notifications} = state;
  const notificationToRemoveIndex = notifications.findIndex(
    notification => notification.id === id
  );

  state.notifications.splice(notificationToRemoveIndex, 1);
};

/*
*
*/
export const track = ({effects}, {name, data}) => {
  effects.analytics.track(name, data);
};

/*
*
*/
export const signInGithubClicked = async ({state, actions}) => {
  state.isLoadingGithub = true;
  await actions.internal.signIn({useExtraScopes: true});
  state.isLoadingGithub = false;
};

/*
*
*/
export const signOutGithubIntegration = async ({
                                                 state,
                                                 effects,
                                               }) => {
  if (state.currentUser?.integrations?.github) {
    await effects.api.signoutGithubIntegration();
    delete state.currentUser.integrations.github;
  }
};

export const setAddressRef = ({state}, ref) => {
  state.addressRef = ref;
};

export const startLoading = ({state}) => {
  state.isLoading = true;
};

export const finishLoading = ({state}) => {
  state.isLoading = false;
};
