import Vue from 'vue';
import Router from 'vue-router';
// import { state } from '@/store/state';

Vue.use(Router)

const routes = [
  // LOGIN-REGISTER
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/pages/Login'),
    meta: {
      requiresAuth: false,
      removeFromNavBar: true
    }
  },
  {
    path: '/register',
    name: 'Signup',
    component: () => import('@/pages/Register'),
    meta: {
      requiresAuth: false,
      removeFromNavBar: true
    }
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import('@/pages/ForgotPassword'),
    meta: {
      requiresAuth: false,
      removeFromNavBar: true
    }
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: () => import('@/pages/ResetPassword'),
    meta: {
      requiresAuth: false,
      removeFromNavBar: true
    }
  },
  //
  {
    path: '/',
    name: 'Home',
    component: () => import('@/pages/Home'),
    meta: {
      // layout: 'authenticated',
      requiresAuth: false,
      removeFromNavBar: true
    }
  },
  {
    path: '/cart',
    name: 'Cart',
    component: () => import('@/pages/Cart'),
    meta: {
      // layout: 'authenticated',
      requiresAuth: false,
      removeFromNavBar: true
    }
  },
  {
    path: '/cart-success',
    name: 'CartSuccess',
    component: () => import('@/pages/CartSuccess'),
    meta: {
      // layout: 'authenticated',
      requiresAuth: false,
      removeFromNavBar: true,
    }
  },
  {
    path: '/order-detail',
    name: 'OrderDetail',
    component: () => import('@/pages/OrderDetail'),
    meta: {
      // layout: 'authenticated',
      requiresAuth: false,
      removeFromNavBar: true,
    }
  },
  {
    path: '/wallet',
    name: 'Wallet',
    component: () => import('@/pages/Wallet'),
    meta: {
      // layout: 'authenticated',
      requiresAuth: false,
      removeFromNavBar: true
    }
  },
  {
    path: '/product',
    name: 'Product',
    component: () => import('@/pages/Product'),
    meta: {
      // layout: 'authenticated',
      requiresAuth: false,
      removeFromNavBar: true
    }
  },
  {
    path: '/product-offer',
    name: 'ProductOffer',
    component: () => import('@/pages/ProductOffer'),
    meta: {
      // layout: 'authenticated',
      requiresAuth: false,
      removeFromNavBar: true
    }
  },
  {
    path: '/transactions',
    name: 'Transactions',
    component: () => import('@/pages/Transactions'),
    meta: {
      // layout: 'authenticated',
      requiresAuth: false,
      removeFromNavBar: true
    }
  },
  {
    path: '/terms',
    name: 'Terms',
    component: () => import('@/pages/Terms'),
    meta: {
      // layout: 'authenticated',
      requiresAuth: false,
      removeFromNavBar: true
    }
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: () => import('@/pages/Privacy'),
    meta: {
      // layout: 'authenticated',
      requiresAuth: false,
      removeFromNavBar: true,
    }
  },
  {
    path: '/addresses',
    name: 'Addresses',
    component: () => import('@/pages/Addresses'),
    meta: {
      requiresAuth: false,
      removeFromNavBar: true
    }
  },
  {
    path: '/address',
    name: 'Address',
    component: () => import('@/pages/DeliverTo'),
    meta: {
      requiresAuth: false,
      removeFromNavBar: true
    }
  },
  {
    path: '/share',
    name: 'Share',
    component: () => import('@/pages/Share'),
    meta: {
      requiresAuth: false,
      removeFromNavBar: true,
    }
  },
  {
    path: '/support',
    name: 'Support',
    component: () => import('@/pages/Support'),
    meta: {
      requiresAuth: false,
      removeFromNavBar: true,
    }
  },
  {
    path: '/payments',
    name: 'Payments',
    component: () => import('@/pages/Payments'),
    meta: {
      requiresAuth: false,
      removeFromNavBar: true,
    }
  },
  {
    path: '/addPayment',
    name: 'AddPayment',
    component: () => import('@/pages/AddPayment'),
    meta: {
      requiresAuth: false,
      removeFromNavBar: true
    }
  }
];

const router = new Router({
  routes
});

// router.beforeEach( (to, from, next) => {
//   console.log('queries', from.query, to.query);
//   if (from.query.event && !to.query.event) {
//     return next({name: to.name, query: {...to.query, ...{event: 'hush'}}})
//   }
//   next();
// });

// router.beforeEach(async (to, from, next) => {
//   const derivedState = (from && from.matched && from.matched[0] && from.matched[0]['instances'] && from.matched[0]['instances']['default']) ? from.matched[0]['instances']['default']['state'] : state;
//   let homeRoute = 'Home';
//   const loginRoute = 'Login';
//
//   if (to.matched.some(routeRecord => routeRecord.meta.requiresAuth)) {
//     if (!derivedState.isLoggedIn) next({ name: loginRoute })
//     else {
//       if (derivedState.authToken) next();
//       else next({ name: loginRoute });
//     }
//   } else {
//     if (derivedState.isLoggedIn) next({ name: homeRoute });
//     else next();
//   }
// });

// cannot call both beforeEach and beforeResolve, if do, then must resolve next in each will be called right before the navigation is confirmed after all in-component guards and async route components are resolved
// router.beforeResolve((to, from, next) => {
//   next();
// })

// cannot affect the navigation
// router.afterEach((to, from) => {
//   //..
// })

export default router
