import numeral from 'numeral';
export const formatCurrencyUnd = function (price, format = '00') {
  const decimals = format.length;
  const dec = Math.floor(price);
  const und = (Number(Math.round(price + 'e' + decimals) + 'e-' + decimals) - dec) * 100;
  return numeral(und).format(format);
};

export const floor = function (value, place) {
  const pow = Math.pow(10, place || 0); // eslint-disable-line
  const val = Math.floor(value * pow) / pow;
  return val.toFixed(place);
};

export const formatCurrencyDec = function (price, format = '$0,0') {
  const nprice = numeral(floor(price, 0));
  return nprice.format(format);
};
