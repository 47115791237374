<template>
	<div :class="`custom-alert alert alert-${type}`" v-if="visible" style="z-index: 1000000000 !important;">
		{{message}}
	</div>
</template>

<script>
  import {reactive, toRefs} from "@vue/composition-api";

  export default {
    props: ['type', 'message', 'visible'],
    setup(props, {root}) {
      const data = reactive({});
      const hideAlert = () => {
        root.actions.alert.hide();
      };
      return {
        hideAlert,
        ...toRefs(data)
      }
    },
    watch: {
      visible: function (isVisible) {
        isVisible && setTimeout(() => this.hideAlert(), 3000)
      }
    }
  }
</script>
<style scoped>
	.custom-alert {
		position: absolute;
		top: 10px;
		right: 10px;
		z-index: 10000000000000 !important;
	}
	
	@media (max-width: 747px) {
		.custom-alert {
			position: fixed;
			left: 10px;
			bottom: 0;
			top: auto;
			z-index: 10000000000000 !important;
		}
	}
</style>
