import { get, isNil, map, keyBy, isEmpty } from 'lodash';
import {json} from "overmind";

/*
*
*/
export const getTotalUsers = async ({ state, effects }) => {
  const { users } = await effects.gql.queries.users();
  state.user.totalRecords = users ? users.length : 0;
}

/*
*
*/
export const filterUsers = async ({ state, effects }, filter) => {
  try {
    state.user.isLoading = true;
    const result = await effects.gql.queries.users({ match: filter });
    // await actions.search.search({keywords: userSearchKeyword, userId: currentUser.id});

    console.log(result, 'result');

    state.user.isLoading = false;
    state.user.filteredUsers = result.users;

  } catch (e) {
    // actions.alert.showError('Failed to update user', Title);
  }
}

export const updateUserProfile = async ({state, effects}, data) => {
  console.log({userId: state.currentUser.id, ...data})
  try {
    const {updateUser} = await effects.gql.mutations.updateUserProfile({userId: state.currentUser.id, ...data})
    state.currentUser = updateUser.user;
    console.log('CurrentUser =>', updateUser.user);
    return updateUser.user;
  } catch (e) {
    console.log(e)
    return false;
  }
};

export const getPlaceFromCoordinates = async ({state, effects}, data) => {
  const {getPlaceFromCoordinates} = await effects.gql.queries.getPlaceFromCoordinates(data);
  console.log(getPlaceFromCoordinates);
  state.user.currentLocation = getPlaceFromCoordinates;
  return getPlaceFromCoordinates;
};

export const setCurrentLocation = async ({state}, data) => {
  state.user.currentLocation = data;
}
/*
*
*/
export const getUsers = async ({ state, effects }, data) => {
  try {
    let options = {}
    if (isEmpty(data)) {
      options = {
        first: state.user.userPerPage,
        skip: (state.user.activePage - 1) * state.user.userPerPage
      }
    } else {
      options = data;
      if (!data.first) options.first = state.user.userPerPage;
      if (!data.skip) options.skip = (state.user.activePage - 1) * state.user.userPerPage;
    }

    console.log(options, 'getUsers options');

    const { users } = await effects.gql.queries.users(options)

    console.log(users, 'getUsers results');

    state.user.users = keyBy(users, 'id');

  } catch (e) {
    console.log(e, 'getUsers errors');
    // actions.alert.showError({ message: e.response.errors[0]['message'], title: 'Fetching Order' });
  }
}

/*
*
*/
export const getUser = async ({ state, effects }, options) => {
  try {
    console.log(options, 'getUser options');

    const { user } = await effects.gql.queries.user(options);

    console.log(user, 'getUser results');

    state.user.users[user.id] = user;
    return json(user);

  } catch (e) {
    console.log(e, 'getUser errors');
    // actions.alert.showError({ message: e.response.errors[0]['message'], title: 'Fetching Order' });
  }
}

/*
*
*/
export const saveUser = async ({ state, effects, actions }, variables) => {
  console.log('saving user... ', variables);

  try {
    // Delete temporary site name
    if(variables && variables.siteName) delete variables.siteName;

    const { avatar, employer } = variables;

    if (avatar?.base64) {
      Object.assign(variables, {
        avatar: avatar.base64,
        avatarType: avatar.contentType,
      })
    } else {
      delete variables.avatar;
    }

    // Add some location props
    // Check if from google places selector
    const placeId = get(employer, 'details.place_id');
    const coords = get(employer, 'details.coords');

    if (employer?.id) { // when site is picker
      variables.site = { id: employer.id };
    } else if (!isNil(placeId)) { // if has google place id
      variables.googlePlacesId = placeId;

    } else if (!isNil(coords)) { // When selected from current location
      variables.gps = { lat: employer.details.coords.latitude, lon: employer.details.coords.longitude };
    }
    if (employer?.address2) variables.address2 = employer.address2;
    // set timezone offset and timezone
    variables.timezoneOffset = (new Date()).getTimezoneOffset();
    variables.userId = state.currentUser.id;
    // variables.timezone = RNLocalize.getTimeZone();

    console.log(variables, 'save user variables')

    const updatedUser = await effects.gql.mutations.updateUserProfile(variables);

    console.log(updatedUser.updateUser.user);

    state.currentUser = updatedUser.updateUser.user;
    return updatedUser;

  } catch (e) {
    console.log(e, Object.keys(e), 'saveUser raw errors');

    if (e?.response?.errors) {
      console.log(map(e.response.errors, 'message'), 'saveUser errors');
      await actions.alert.showError({ message: e.response.errors[0]['message'], title: 'Profile Update' });
    }
  }
};

export const onCreateBraintreeToken = async ({state, effects}) => {
  const {currentUser} = state;
  const {generateBraintreeClientToken} = await effects.gql.mutations.generateBraintreeClientToken({customerId: currentUser.braintreeCustomerId});
  state.token = generateBraintreeClientToken;
  return generateBraintreeClientToken;
};

export const onAddPayment = async ({state, effects}, paymentMethod) => {
  const {updateUser} = await effects.gql.mutations.updateUserProfile({ paymentMethod, userId: state.currentUser.id });
  console.log(updateUser);
  state.currentUser = updateUser.user;
  return updateUser.user;
};

/*
*
*/
export const onChangePage = async ({ state }, page) => {
  state.user.activePage = page
}

/*
*
*/
export const onUserAdded = ({ state }, data) => {
  state.user.push(data)
}

/*
*
*/
export const requestSupport = async ({ effects, actions }, data) => {
  try {
    await effects.gql.mutations.requestSupport(data);
    await actions.alert.showSuccess({ message: 'Someone will be in touch with you ASAP. Thanks!', title: 'FamousJerk' });
    return true;

  } catch (e) {
    console.log(e, 'requestSupport errors');
  }
}

export const deleteUserAddress = async ({state, effects}, data) => {
  try {
    const {deleteUserAddress} = await effects.gql.mutations.deleteUserAddress(data);
    console.log(deleteUserAddress);
    state.currentUser = deleteUserAddress;
    return deleteUserAddress;
  } catch (e) {
    console.log(e);
  }
};
