<template>
  <div id="app">
    <vue-confirm-dialog></vue-confirm-dialog>
    <component :is="layout">
      <router-view/>
    </component>
  </div>
</template>

<script>
  import 'bootstrap/dist/js/bootstrap.min.js';

  const default_layout = 'default';

  export default {
    name: "app",
    computed: {
      layout() {
        return (this.$route.meta.layout || default_layout) + '-layout';
      }
    },
    metaInfo: {
      title: 'Famous Jerk',
      titleTemplate: '%s | Caribbean Chicken'
    }
  }
</script>

<style>

</style>
