<template lang="html">
	<div class="modal fade" id="paymentsModal" tabindex="-1" role="dialog" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered modal-mobile" role="document" style="height: 653px; margin:auto">
			<div class="modal-content" style="border-radius: 20px !important; height: 653px; margin: 0 10px;">
				<div class="popup-login">
					<button class="btn close-btn" data-dismiss="modal" style="top: 27px; right: 20px;">X</button>
					<div class="login-title" style="margin-top: 32px; margin-bottom: 10px;">PAYMENT METHODS</div>
					<div style="max-height: 500px; position: relative; overflow-y: scroll">
						<div style="width: 25px; font-size: 11px; color: grey; font-weight: 700; text-align: center; margin-left: 7px; margin-bottom: -10px;" v-if="get(state, 'currentUser.paymentMethods', []).filter(p => p.type === 'payment' && p.isValid).length > 0">DEFAULT</div>
						<a class="address-content" v-for="(payment, i) in get(state, 'currentUser.paymentMethods', []).filter(p => p.type === 'payment' && p.isValid)" :key="i" @click="setAddressIndex(payment.id)">
							<div style="width: 25px; justify-content: center; align-items: center">
								<img src="@/assets/images/icon_check.png" style="width: 20px" v-if="payment.isDefault">
							</div>
							<img :src="payment.image" style="width: 40px; margin-left: 10px;">
							<div class="address-text ml-4 mr-2" :style="payment.isDefault ? 'opacity: 1': 'opacity: 0.4'">
								{{`Card ending in ${payment.last4}`}}
							</div>
							<button class="btn edit" @click.stop.prevent="onDeletePayment(payment)">
								DELETE
							</button>
						</a>
					</div>
					<button class="btn btn-login" style="position: absolute; bottom: 0; width: 92%;" type="button" @click="onPressContinue">
						ADD PAYMENT METHOD
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
  import {reactive, toRefs} from '@vue/composition-api';
  import {get} from 'lodash';
  import $ from 'jquery';

  export default {
    props: ['selectedIndex', 'updateSelectPayment'],
    setup(props, {root}) {
      const data = reactive({
        index: null,
      });
      const onPressContinue = () => {
        $('#addPaymentModal').modal('show');
      };

      const setAddressIndex = async (id) => {
        data.index = id;
        console.log(data.index, id)
        await root.actions.startLoading();
        try {
          await root.actions.paymentMethod.setPaymentMethodToDefault(id);
          if (props.updateSelectedPayment) {
            props.updateSelectedPayment(id);
          }
        } catch (e) {
          console.log(e);
        } finally {
          await root.actions.finishLoading();
        }
      };

      const onDeletePayment = (payment) => {
        if (payment.isDefault) {
          root.actions.alert.showError({message: 'Choose another default payment method!'});
        } else {
          const loader = root.$loading.show();
          try {
            const methods = [];
            methods.push(payment.token);
            root.actions.user.saveUser({removePaymentMethods: methods});
          } catch (e) {
            console.log(e);
          } finally {
            loader.hide();
          }
        }
      };
      return {
        onPressContinue,
        setAddressIndex,
        onDeletePayment,
        get,
        ...toRefs(data)
      }
    },
    beforeUpdate() {
      if (this.selectedIndex) {
        this.index = this.selectedIndex;
      }
    },
  }
</script>

<style lang="css" scoped>
	input:focus {
		outline-width: 0 !important;
	}
	
	.edit {
		border: 1px solid #e0e0e0;
		box-sizing: border-box;
		border-radius: 2px;
		width: 50px;
		height: 21px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-weight: bold;
		font-size: 8px;
		line-height: 23px;
		color: #727272;
		background-color: white;
		position: absolute;
		right: 25px;
		padding: 0;
	}
	
	.address-content {
		display: flex;
		flex-direction: row;
		align-items: center;
		padding: 10px 25px;
		margin-top: 5px;
	}
	
	.address-text {
		font-weight: 600;
		font-size: 12px;
		color: black;
	}
</style>
