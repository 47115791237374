import {createOvermind} from 'overmind'
import {merge, namespaced} from 'overmind/config'
import {createPlugin} from 'overmind-vue'
import {onInitialize} from './onInitialize'
import {state} from './state'
import * as effects from './effects';
import * as actions from './actions';

// custom
import * as user from './namespaces/user';
import * as notification from './namespaces/notification';
import * as google from './namespaces/google';
import * as site from './namespaces/site';
import * as event from './namespaces/event';
import * as cart from './namespaces/cart';
import * as product from './namespaces/product';
import * as dateSlider from './namespaces/dateSlider';
import * as search from './namespaces/search';
import * as alert from './namespaces/alert'
import * as order from './namespaces/order'
import * as paymentMethod from './namespaces/paymentMethod'
import * as wallet from './namespaces/wallet'
// import * as event from './namespaces/event'

export const config = merge(
  {
    state,
    effects,
    actions,
    onInitialize,
  },
  namespaced({
    // event,
    alert,
    user,
    notification,
    google,
    site,
    cart,
    event,
    product,
    dateSlider,
    search,
    order,
    paymentMethod,
    wallet
  })
);

const overmind = createOvermind(config, {
  devtools: true // defaults to localhost:3031
});

export const OvermindPlugin = createPlugin(overmind)
