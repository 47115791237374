<template lang="html">
	<div class="modal fade" id="loginModal" tabindex="-1" role="dialog" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 403px; width: 100%; margin:auto">
			<div class="modal-content" style="border-radius: 20px !important;">
				<div class="popup-login">
					<button class="btn close-btn" data-dismiss="modal" style="top: 12px;">X</button>
					<div class="login-title">LOGIN</div>
					<div class="login-desc">Login to take advantage of this special offer.</div>
					<div class="login-input-view">
						<div class="login-input-title">{{step === 0 ? 'MOBILE NUMBER' : 'VERIFICATION CODE'}}</div>
						<the-mask class="login-input" mask="(###) ###-####" v-model="mobileNumber" v-if="step === 0"></the-mask>
						<input class="login-input" id="verification_code" v-model="verificationCode" @focus="onFocus()" @blur="onBlur()" v-else/>
					</div>
					<div class="login-desc" style="margin-top: 36px;" v-if="step === 0">
						We'll send you a 6-digit verification code.
					</div>
					<a class="login-resend" v-if="step === 1" @click="onResend">Resend Verification Code</a>
					<button class="btn btn-login d-flex justify-content-center align-items-center" type="button" @click="login" v-if="step === 0">
						<div class="loader" v-if="state.isLoading"></div>
						<div v-else>CONTINUE</div>
					</button>
					<button class="btn btn-login d-flex justify-content-center align-items-center" type="button" @click="verify" v-else>
						<div class="loader" v-if="state.isLoading"></div>
						<div v-else>SUBMIT VERIFICATION CODE</div>
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
  import {reactive, toRefs} from '@vue/composition-api'
  import $ from 'jquery'

  export default {
    setup(props, {root}) {
      const data = reactive({
        mobileNumber: '',
        user: null,
        step: 0,
        verificationCode: null,
      });
      const validNumber = (number) => {
        return number.length === 10;
      };

      const _createAppUser = async (generateToken) => {
        await root.actions.startLoading();
        try {
          const res = await root.actions.createAppUser({mobileNumber: data.mobileNumber, generateToken});

          console.log('Verification Code: ', res.verificationCode);
          data.user = res.user;
          data.step = 1;

        } catch (exception) {
          await root.actions.alert.showError({message: 'Request to send verification code failed'})
          console.log('onLogin() - ', exception)
        } finally {
          await root.actions.finishLoading();
        }
      };
      const login = async () => {
        const number = data.mobileNumber.replace(/[^A-Z0-9]/ig, "");
        if (!validNumber(number)) {
          root.actions.alert.showError({message: 'Please input valid phone number'});
          return;
        }
        await _createAppUser(true);
      };

      const verify = async () => {
        await root.actions.startLoading();
        try {
          const {token} = await root.actions.verifySmsCode({
            userId: data.user?.id,
            verifyCode: data.verificationCode,
            mobileNumber: data.mobileNumber
          });

          console.log('onVerify() - got token', token, data.user);

          await root.actions.loginWithToken({token, userId: data.user.id});
          await root.actions.finishLoading();
          $('#loginModal').modal('hide');
          if (!root.state.currentUser.email || !root.state.currentUser.fullName || !root.state.currentUser.gender) {
            $('#registerModal').modal('show');
          }
          
        } catch (error) {
          await root.actions.finishLoading();
          await root.actions.logout();
          root.actions.alert.showError({message: 'Failed to verify the sms code'});
          console.log('onVerify() - ', error);
        } finally {
          await root.actions.finishLoading();
        }
      };

      const onResend = () => {
        _createAppUser(!data.user)
      };
      const onFocus = () => {
        const searchField = document.getElementById('verification_code');
        searchField.setAttribute('autofocus', 'autofocus');
        searchField.focus();
      };

      const onBlur = () => {
        const searchField = document.getElementById('verification_code');
        searchField.removeAttribute('autofocus');
      };
      return {
        login,
        verify,
        onResend,
        onFocus,
				onBlur,
        ...toRefs(data)
      }
    }
  }
</script>

<style lang="css">

	.popup-login {
		border-radius: 20px;
		background-color: white;
		display: flex;
		flex-direction: column;
		box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.25);
	}

	.login-resend {
		text-align: center;
		font-weight: bold;
		font-size: 14px;
		margin-top: 20px;
		margin-bottom: 10px;
	}

	.login-title {
		margin-top: 15px;
		font-weight: bold;
		font-size: 15px;
		line-height: 18px;
		text-align: center;
	}

	.login-desc {
		font-size: 12px;
		line-height: 25px;
		color: #959999;
		text-align: center;
		margin-top: 5px;
	}

	.login-input-view {
		border-top-width: 1px;
		border-bottom-width: 1px;
		border-left-width: 0;
		border-right-width: 0;
		border-style: solid;
		border-color: #d8d8d8;
		box-sizing: border-box;
		margin-top: 25px;
	}

	.login-input-title {
		margin: 8px 16px 0 16px;
		font-weight: 600;
		font-size: 11px;
		line-height: 21px;
		letter-spacing: 0.0916666px;
		color: #808080;
		mix-blend-mode: normal;
		opacity: 0.54;
	}

	.login-input {
		border: none;
		background-color: transparent;
		padding: 1px 16px;
		text-align: center;
		width: 100%;
		margin-bottom: 5px;
		margin-top: 5px;
		font-size: 20px;
	}

	input.login-input:focus {
		outline-width: 0 !important;
	}

	.btn-login {
		background-color: #c30000;
		border-radius: 5px;
		padding: 14px;
		font-weight: bold;
		font-size: 14px;
		line-height: 17px;
		color: white;
		margin: 5px 16px 18px 16px;
		box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
	}
</style>
