<template>
	<div class="clearfix h-screen">
		<Header class=""/>
		<div class="main-content">
			<slot/>
		</div>
		<Footer class=""/>
	</div>
</template>

<script>
  import Header from "@/components/Header";
  import Footer from "@/components/Footer";

  export default {
    name: "Authenticated",
    components: {
      Header,
      Footer
    },
    watch: {
      $route: function () {
        window.scrollTo(0, 0);
      },
    },
    mounted() {
      console.log("loaded Authenticated");
    },
    computed: {
      user: (root) => {
        return root.state.currentUser ? root.state.currentUser : {};
      }
    }
  };
</script>
