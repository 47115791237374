import gql from 'graphql-tag';
import {notificationFragment} from '@/store/effects/gql/notification/fragments';

export const PaymentMethod = `
{
    id
    type
    cardType
    token
    last4
    isValid
    isDefault
    image
    expirationDate
    createdDate
}
`;
/*
*
*/
export const userFragment = gql`{
 id
  balance
  bio
  appVersion
  needToUpgrade
  timezoneOffset
  chatId
  playerId
  gender
  timezone
  firstName
  lunchtime
  firstNameLower
  middleName
  middleNameLower
  lastName
  lastNameLower
  fullName
  fullNameLower
  dateOfBirth
  email
  avatar
  braintreeCustomerId
  createdAt
  updatedAt
  site { id name address address2 city state postalCode country phones{id number} googlePlacesId gps{id lat lon} }
  sites { id name address address2 city state postalCode country phones{id number} googlePlacesId gps{id lat lon}}
  paymentMethods${PaymentMethod}
  groups { id name }
  orders { id }
  unreadNotifications${notificationFragment}
}`;

/*
*
*/
export const AuthPayLoad = gql`
{
  user${userFragment}
  verificationCode
}
`;


