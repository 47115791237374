<template>
  <header class="header header-sm" :class="routeClass">
    <div class="w-100 hidden-sm">
      <div class="row align-items-center">
        <router-link :to="{ name: 'Home', query: isEvent ? {event: 'hush'} : {} }" class="col-lg-2 col-md-3 col-6">
          <img src="@/assets/images/famousjerk-logo.png" alt="Flute" class="header-logo"/>
        </router-link>
        <div class="col-lg-8 col-md-6 location">
          <div
            style="display: flex; flex-direction: row; align-items: center; justify-content: space-between; height: 100%;">
            <div style="display: flex; flex-direction: row; width: 100%;">
              <img src="@/assets/images/icon_cursor.png" class="cursor">
              <input class="location-text" placeholder="Enter Delivery Address"
                     style="background-color: transparent; width: 100%" v-model="address" ref="addressRef"/>
            </div>
            <button class="btn button d-flex justify-content-center align-items-center" @click="handleOffer">
              <div class="loader" v-if="state.isLoading"></div>
              <span v-else>GO</span>
            </button>
          </div>
          <div class="list-view" v-if="isOpen">
            <a class="address-item" v-for="(place, index) in state.google.placeResults" :key="index"
               @click="onClickAddress(index)">{{place.formatted_address}}</a>
          </div>
        </div>
        <div class="clearfix col-lg-2 col-md-3 col-6">
          <div class="d-flex align-items-center">
            <a
              @click="get(state, 'currentUser.id') ? ($route.name !== 'Cart' && $router.push({path: '/cart', query: isEvent ? {event: 'hush'}: {}})) : isModal ? $('#loginModal').modal('show'): $router.push('/login')"
              class="mr-4 ml-auto">
              <img src="@/assets/images/icon_cart.png" alt="" class="cart img-fluid"/>
              <div class="count" v-if="get(state, 'currentCart.items')">
                {{getCount()}}
              </div>
            </a>
            <dropdown-menu v-model="show" :right="right" v-if="get(state, 'currentUser.id')">
              <a class="dropdown-toggle">
                <div class="balance">
                  <div class="circle hidden-sm">
                    <img :src="get(state, 'currentUser.avatar')"
                         style="width: 40px; height: 40px; object-fit: cover; border-radius: 20px;"
                         v-if="get(state, 'currentUser.avatar')">
                    <img src="@/assets/images/avatar-female-1.png" style="width: 35px; margin-bottom: 3px;"
                         v-else-if="get(state, 'currentUser.gender') === 'Female'">
                    <img src="@/assets/images/avatar-male-1.png" style="width: 35px; margin-bottom: 3px;" v-else>
                  </div>
                  <div
                    style="display: flex; flex-direction: column; justify-content: center; align-items: center; margin-left: 3px; margin-right: 3px;">
                    <div
                      style="border-radius: 3px; padding: 3px; font-weight: 500; font-size: 7.5px; line-height: 9px;">
                      WALLET BALANCE
                    </div>
                    <div class="row" style="margin-top: -3px;">
                      <span style="font-weight: 500; font-size: 19px; line-height: 23px; margin-top: 2px;">{{formatCurrencyDec(get(state, 'currentUser.balance', 0))}}</span>
                      <span style="font-weight: 500;font-size: 10px;line-height: 20px; margin-left: 2px;">{{formatCurrencyUnd(get(state, 'currentUser.balance', 0))}}</span>
                    </div>
                  </div>
                </div>
              </a>
              <div slot="dropdown">
                <a class="dropdown-item" @click="editProfile">Edit Profile</a>
                <router-link class="dropdown-item" :to="{name: 'Transactions', query: isEvent ? {event: 'hush'}: {}}">
                  Order History
                </router-link>
                <!--								<router-link class="dropdown-item" :to="{name: 'Wallet'}">Wallet</router-link>-->
                <a class="dropdown-item" @click="onClickPayment">Payment Methods</a>
                <a class="dropdown-item" @click="logout">Logout</a>
              </div>
            </dropdown-menu>
            <button class="btn button position-static px-3 ml-1" type="button" @click="onClickLogin" v-else>
              <span>SIGN IN</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="w-100 show_sm justify-content-center align-items-center" style="height: 350px;">
      <dropdown-menu v-model="show" :right="false" v-if="get(state, 'currentUser.id')"
                     style="position: absolute; left: 10px;">
        <a class="dropdown-toggle">
          <button class="btn"><img src="@/assets/images/icon_menu.png" style="width: 30px; height: 30px;"/></button>
        </a>
        <div slot="dropdown">
          <a class="dropdown-item" @click="editProfile">Edit Profile</a>
          <router-link class="dropdown-item" :to="{name: 'Transactions', query: isEvent ? {event: 'hush'}: {}}">Order
            History
          </router-link>
          <!--								<router-link class="dropdown-item" :to="{name: 'Wallet'}">Wallet</router-link>-->
          <a class="dropdown-item" @click="onClickPayment">Payment Methods</a>
          <a class="dropdown-item" @click="logout">Logout</a>
        </div>
      </dropdown-menu>
      <router-link :to="{ name: 'Home', query: isEvent ? {event: 'hush'} : {} }"
                   class="logo-sm d-flex justify-content-center align-items-center">
        <img src="@/assets/images/famousjerk-logo.png" alt="Flute" class="logo-mobile"/>
      </router-link>
      <a
        @click="get(state, 'currentUser.id') ? ($route.name !== 'Cart' ? $router.push({path: '/cart', query: isEvent ? {event: 'hush'}: {}}) : '') : isModal ? $('#loginModal').modal('show'): $router.push('/login')"
        style="position: absolute; right: 20px;">
        <img src="@/assets/images/icon_cart.png" alt="" class="cart img-fluid"/>
        <div class="count" v-if="get(state, 'currentCart.items')">
          {{getCount()}}
        </div>
      </a>
    </div>
    <div style="width: 100vw;  background-color: white;" class="show_sm header-sm">
      <div class="location show_sm">
        <div
          style="display: flex; flex-direction: row; align-items: center; justify-content: space-between; height: 100%;"
          class="header-input-width">
          <div style="display: flex; flex-direction: row; width: 100%;">
            <img src="@/assets/images/icon_cursor.png" class="cursor">
            <input class="location-text" placeholder="Enter Delivery Address"
                   style="background-color: transparent; width: 100%" v-model="address"/>
          </div>
          <button class="btn button d-flex justify-content-center align-items-center" @click="handleOffer"
                  style="margin-right: 10px;">
            <div class="loader" v-if="state.isLoading"></div>
            <span v-else>GO</span>
          </button>
        </div>
        <div class="list-view" v-if="isOpen">
          <a class="address-item" v-for="(place, index) in state.google.placeResults" :key="index"
             @click="onClickAddress(index)">{{place.formatted_address}}</a>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
  import {get} from 'lodash';
  import {reactive, toRefs} from '@vue/composition-api';
  import {json} from "overmind";
  import DropdownMenu from '@innologica/vue-dropdown-menu';
  import $ from 'jquery'
  import {formatCurrencyDec, formatCurrencyUnd} from "../utils/utils";
  import {Settings} from '../../settings';

  export default {
    components: {
      DropdownMenu,
    },
    setup(props, {root}) {
      const offerAddress = JSON.parse(localStorage.getItem('famous_jerk_offerAddress'));
      const data = reactive({
        address: offerAddress?.formatted_address,
        site: offerAddress,
        isOpen: false,
        right: true,
        get,
        show: null,
        $,
        isModal: false,
      });

      const searchGooglePlaces = async (val) => {
        try {
          // setTimeout(async () => {
          await root.actions.google.searchGooglePlaces({keyword: val});
          data.isOpen = true;
          // }, 300)
        } catch (e) {
          console.log(e)
        }
      };

      const onClickAddress = async (index) => {
        data.site = json(root.state.google.placeResults[index]);
        data.address = data.site.formatted_address;
        root.actions.user.setCurrentLocation(data.site);
        await localStorage.setItem('famous_jerk_offerAddress', JSON.stringify(data.site));
        setTimeout(() => {
          data.isOpen = false;
        }, 1000)
      };

      const handleOffer = async () => {
        if (!data.site?.place_id) {
          root.actions.alert.showError({message: 'Please select offer!'});
          return;
        }
        await root.actions.startLoading();
        try {
          // const params = {siteId: data.site.place_id};
          // if (root.state.currentUser?.id) {
          //   params.userId = root.state.currentUser?.id;
          //   await root.actions.ad.checkForQualifiedAds(params);
          // } else {
          //   await root.actions.ad.checkForQualifiedAdsByLocation(params);
          // }
          root.$router.push({path: '/product-offer', query: root.$route.query.event === 'hush' ? {event: 'hush'} : {}})
        } catch (e) {
          console.log(e.message);
        } finally {
          await root.actions.finishLoading();
        }
      };
      const getAddress = async () => {
        const offerAddress = JSON.parse(await localStorage.getItem('famous_jerk_offerAddress'));
        data.address = offerAddress?.formatted_address;
        data.site = offerAddress;
        setTimeout(() => data.isOpen = false, 1500)
      };

      const editProfile = () => {
        if (data.isModal) {
          data.toggleModal = !data.toggleModal;
          $('#registerModal').modal('show');
        } else {
          root.$router.push('/register');
        }

      };

      const onClickPayment = () => {
        if (get(root.state, 'currentUser.paymentMethods', []).filter(p => p.type === 'payment' && p.isValid)?.length === 0) {
          if (data.isModal) {
            $('#addPaymentModal').modal('show');
          } else {
            root.$router.push('/addPayment')
          }
        } else {
          if (data.isModal) {
            $('#paymentsModal').modal('show');
          } else {
            root.$router.push('/payments')
          }

        }
      };

      const logout = async () => {
        await root.actions.logout();
        await root.$router.push({path: '/', query: root.$route.query.event === 'hush' ? {event: 'hush'} : {}})
      };
      const getCount = () => {
        let count = 0;
        root.state.currentCart?.items?.map(i => count += i.quantity);
        return count;
      };
      const onClickLogin = () => {
        if (data.isModal) {
          $('#loginModal').modal('show')
        } else {
          root.$router.push('/login')
        }
      }
      return {
        searchGooglePlaces,
        onClickAddress,
        handleOffer,
        getAddress,
        logout,
        editProfile,
        onClickPayment,
        formatCurrencyUnd,
        formatCurrencyDec,
        onClickLogin,
        getCount,
        $,
        ...toRefs(data)
      }
    },
    computed: {
      routeClass() {
        if (this.$route.name === 'Home') return 'd-none';
        else return ''
      },
      currentRouteName() {
        return this.$route.name;
      },
      isEvent() {
        return this.$route.query.event === 'hush';
      }
    },
    watch: {
      address: async function (val) {
        await this.searchGooglePlaces(val);
      },
      site: function () {
        this.isOpen = false;
      },
      $route: function () {
        this.getAddress();
      }
    },
    beforeMount() {
      this.getAddress();
      this.actions.setRoute(this.$router);
    },
    mounted() {
      this.isModal = Settings.flow === 'modal';
      this.actions.setAddressRef(this.$refs.addressRef);
    }
  }
</script>

<style lang="css" scoped>
  .list-view {
    width: 100%;
    position: absolute;
    background-color: white;
    max-height: 400px;
    left: 0;
    top: 60px;
    border-radius: 5px;
    overflow-y: scroll;
  }

  .address-item {
    display: flex;
    align-items: center;
    padding: 15px 18px;
    border-bottom-width: 1px;
    border-top-width: 0;
    border-left-width: 0;
    border-right-width: 0;
    border-bottom-color: #B4B4B4;
    border-style: solid;
    font-weight: 300;
    font-size: 12px;
    text-transform: uppercase;
  }

  .header {
    width: 100%;
    background-color: #c30000;
    position: fixed;
    height: 130px;
    top: 0;
    z-index: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 37px;
  }

  .header-logo {
    max-width: 200px;
    width: 100%;
  }

  .cart {
    width: 44px;
  }

  .count {
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    position: absolute;
    margin-top: -45px;
    margin-left: 28px;
    text-align: center;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background-color: white;
    color: black;
    display: flex;
  }

  .circle {
    background: #DEDEDE;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    margin-top: 5px;
    margin-bottom: 5px;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .balance {
    border-radius: 25px 7px 7px 25px;
    background-color: white;
    height: 48px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 3px;
  }

  .show_sm {
    display: none !important;
  }

  .header-input-width {
    width: 70%;
  }

  @media (max-width: 1025px) {
    .header-input-width {
      width: 85%;
    }

    .logo-mobile {
      width: 80%;
    }

    .list-view {
      top: 145px;
    }

    .location {
      flex: 1;
      border: 1px solid #E8E8E8;
      box-sizing: border-box;
      border-radius: 5px;
    }

    .logo-sm {
      margin: 10px 0 !important;
    }

    .hidden-sm {
      display: none !important;
    }

    .show_sm {
      display: flex !important;
    }

    .location {
      flex: 1;
      border: 1px solid #E8E8E8;
      box-sizing: border-box;
      border-radius: 5px;
    }

    .header-logo {
      width: 70%;
      max-width: 130px;
    }

    .button {
      font-size: 10px;
      line-height: 12px;
      padding: 11px 15px;
      margin-right: 5px;
    }

    .cart {
      width: 40px;
    }

    .count {
      margin-top: -42px;
      font-size: 12px;
      margin-left: 25px;
    }

    .header {
      height: 100px;
      flex-direction: column;
    }
  }


  @media (max-width: 767px) {
    /*.hidden-sm {*/
    /*	display: none;*/
    /*}*/
    /*.header-sm {*/
    /*	height: 200px !important;*/
    /*}*/
    .location {
      flex: 1;
      border: 1px solid #E8E8E8;
      box-sizing: border-box;
      border-radius: 5px;
    }

    .header-logo {
      width: 70%;
      max-width: 130px;
    }

    .button {
      font-size: 10px;
      line-height: 12px;
      padding: 11px 15px;
      margin-right: 5px;
    }

    .cart {
      width: 40px;
    }

    /*.count {*/
    /*	margin-top: -32px;*/
    /*	font-size: 12px;*/
    /*	margin-left: -5px;*/
    /*}*/
    .header {
      height: 100px;
      flex-direction: column;
    }
  }
</style>
