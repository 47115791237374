import Vue from 'vue'
import App from './App.vue'
import VueCompositionApi from "@vue/composition-api";

import { registerSharedComponents } from '@/plugins/register'
import { OvermindPlugin } from './store'

import router from './router'
import underscore from 'vue-underscore';
import Loading from 'vue-loading-overlay';
import VueConfirmDialog from 'vue-confirm-dialog'
import VueTheMask from 'vue-the-mask';
import VueGtag from "vue-gtag";
import 'remixicon/fonts/remixicon.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'vue-loading-overlay/dist/vue-loading.css';
import VueBraintree from 'vue-braintree'
import VueSocialSharing from 'vue-social-sharing';
import VueMeta from 'vue-meta'
import VTooltip from "v-tooltip";

// CSS
import './assets/css/home.css'
import './main.css'

Vue.config.productionTip = false

// register globally
Vue.use(VTooltip);
Vue.use(underscore);
Vue.use(VueCompositionApi);
Vue.use(OvermindPlugin);
Vue.use(VueTheMask);
Vue.use(VueBraintree);
Vue.use(VueSocialSharing);
Vue.use(VueGtag, {
  config: { id: "UA-3410553-4" },
  // appName: 'CHUNE',
  // pageTrackerScreenviewEnabled: true
}, router);
Vue.use(Loading, {
  canCancel: false, // default false
  color: '#000000',
  loader: 'bars',
  width: 64,
  height: 64,
  backgroundColor: '#ffffff',
  opacity: 0.5,
  zIndex: 99999999999,
  isFullPage: true
});

Vue.use(VueConfirmDialog);
Vue.use(VueMeta);

//
/** Globally register layout component */
import Default from './layouts/Default';
import Authenticated from './layouts/Authenticated';

Vue.component('default-layout', Default);
Vue.component('authenticated-layout', Authenticated);
Vue.component('vue-confirm-dialog', VueConfirmDialog.default)

// new Vue({
//   setup() {
//     provide(DefaultApolloClient, apolloClient)
//     return {}
//   },
//   router,
//   store,
//   render: h => h(App),
// }).$mount('#app')

registerSharedComponents();

new Vue({
  router,
  render: h => h(App),
}).$mount('#app');
