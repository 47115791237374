module.exports = {
  Settings: {
    appName: 'Famous Jerk',
    appVersion: '1.0',
    mainDomain: 'famousjerk.com',
    groupDomain: 'famousjerk.com',
    siteId: '5e67b2d9364d58030d625e33',
    gqlServer: {
      url: 'https://api-pre.chewbox.com/'
    },
    mailerServer: {
      url: 'https://mailer.flutedrinks.com'
    },
    flow: 'page'
  }
};
