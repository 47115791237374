<template lang="html">
	<div class="modal fade" id="addPaymentModal" tabindex="-1" role="dialog" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered modal-mobile" role="document" style="height: 653px; margin:auto">
			<div class="modal-content" style="border-radius: 20px !important; height: 653px; margin: 0 10px;">
				<div class="popup-login">
					<button class="btn close-btn" data-dismiss="modal" style="top: 27px;">X</button>
					<div class="login-title" style="margin-top: 32px; margin-bottom: 10px;">ADD PAYMENT METHOD</div>
					<div style="margin: 10px;">
						<v-braintree
							:authorization="get(state, 'token', '')"
							@success="onSuccess"
							@error="onError"
							v-if="state.token"
						></v-braintree>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
  import {reactive, toRefs} from '@vue/composition-api';
  import {get} from 'lodash';
  import $ from 'jquery'
  export default {
    props: ['updatePayment'],
    setup(props, {root}) {
      const data = reactive({
				get
      });
      const onSuccess = async (payload) => {
        $('.btn-primary').text('');
        $('.btn-primary').addClass('d-flex justify-content-center align-items-center');
        $('.btn-primary').append('<div class="loader" v-if="state.isLoading"></div>');
        try {
          const param = {
            type: 'payment',
            cardType: payload?.details?.cardType,
            last4: payload?.details?.lastFour,
            token: payload?.nonce,
            isValid: true,
            isDefault: true
          };
          const res = await root.actions.user.onAddPayment(param);
          if (props.updatePayment) {
            props.updatePayment(res);
					}
          $('#addPaymentModal').modal('hide');
        } catch (e) {
          console.log(e);
        } finally {
          $('.btn-primary').text('');
          $('.loader').remove()
        }
      };

      const onError = (error) => {
        console.log(error)
      };
      const getBraintreeToken = async () => {
        const loader = root.$loading.show();
        try {
          if (!root.state.currentUser?.id) {
            const { token, userId } = await root.actions.getStoredAuthToken();
            await root.actions.loginWithTokenForBraintree({ token, userId });
          } else if (root.state.currentUser?.id && !root.state.token) {
            await root.actions.user.onCreateBraintreeToken();
          }
        } catch (e) {
					console.log(e)
        } finally {
					loader.hide();
        }
			};
      return {
        onSuccess,
        onError,
        getBraintreeToken,
        ...toRefs(data)
      }
    },
		mounted() {
      this.getBraintreeToken();
      $('#addPaymentModal').on('shown.bs.modal', () => {
        $('.braintree-toggle').click();
        $('.btn-primary').text('Add');
      })
    }
  }
</script>

<style lang="css" scoped>
	
	select {
		border: 0;
		outline: 0;
	}
	
</style>
